import { Translations } from './utils/translations';
import { MEMBERS_AREA_DEF_ID, DAILY_TIMETABLE_WIDGET_CONTROLLER_ID } from './constants';
import { addBookingsMembersArea, installMembersArea } from './utils/members-area-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './utils/pages-panel-actions';
import { handleLightMigration, handleMigration, shouldProposeMigration } from './utils/migrate-actions';
import { getCurrentVersion } from './utils/ci-actions';
import { proposeMigration } from './utils/migration-modals';
import {
  getAllBookingsPages, getBookingsData, getPageData,
  isBookingsSplitted, onRemoveApp,
  removePage
} from './utils/editor-sdk-actions';
import {
  ConnectionBuilder, ConnectionBuilderFactory
} from './utils/manifest-definitions';

export const createEditorScript = (withSplit, withMigrationProposal) => {
  let instance, appToken, sdk, locale, isAdi, isProposeMigrationNeeded;
  const bookingsDashboardPanel = 'bookings/scheduler/owner/offerings';
  const manageBookingsEvent = 'manageBookings';
  const deleteBookingsEvent = 'deleteBookings';
  const deletePageEvent = 'deletePage';
  const bookingsListPageId = 'bookings_list';
  const pageChangedEvent = 'focusedPageChanged';
  const widgetGfppClicked = 'widgetGfppClicked';
  const translation = new Translations();

  return {
    editorReady: async (_editorSDK, _appToken, options) => {
      locale = await _editorSDK.environment.getLocale();
      const bookingsData = await getBookingsData(_editorSDK, appToken);
      instance = bookingsData.instance;
      await translation.editorInit(locale, await getCurrentVersion(_editorSDK, _appToken));
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        isAdi = options.origin && options.origin.type === 'ADI';
        if (await isBookingsSplitted(sdk)) {
          await addBookingsPagesAsPanel(sdk, appToken);
        }
        if (options && options.firstInstall) {
          if (withSplit) {
            await addBookingsPagesAsPanel(sdk, appToken);
          }
          if (!isAdi) {
            await installMembersArea(sdk, appToken);
          }
        } else if (withMigrationProposal) {
          await sdk.document.application.registerToCustomEvents(appToken, {eventTypes: [pageChangedEvent]});
          isProposeMigrationNeeded = shouldProposeMigration(sdk, isAdi, appToken, instance);
        }
        await createBookCheckoutState(sdk, appToken);
        resolve();
      });
    },
    getAppManifest: () => {
      const connectionFactory = new ConnectionBuilderFactory(translation);
      const mainAppConnection = new ConnectionBuilder()
        .withMainAction1({
          label: translation.t('bookings-pages.actions.manage'),
          actionId: manageBookingsEvent
        })
        .withHelpId('0b516ee7-36ca-48c8-9a1d-95ce926802ef')
        .withConnections({
          dateRangeLabel: connectionFactory.UNEDITABLE_TEXT,
          timetableTitle: connectionFactory.EDITABLE_TEXT,
          leftButton: connectionFactory.LINK,
          rightButton: connectionFactory.LINK,
          slotRepeater: connectionFactory.REPEATER,
          slotContainer: connectionFactory.REPEATER_ITEM,
          daysToolbar: connectionFactory.REPEATER,
          dayItem: connectionFactory.REPEATER_ITEM,
          day: connectionFactory.UNEDITABLE_TEXT,
          statebox: connectionFactory.STATE_BOX,
          loaderImage: connectionFactory.LOADER_IMAGE,
          time: connectionFactory.UNEDITABLE_TEXT,
          dayPickerButton: connectionFactory.LINK_DISABLED_TEXT,
          duration: connectionFactory.UNEDITABLE_TEXT,
          serviceName: connectionFactory.UNEDITABLE_TEXT,
          staffMember: connectionFactory.UNEDITABLE_TEXT,
          spots: connectionFactory.UNEDITABLE_TEXT,
          servicesDivider: connectionFactory.DIVIDER,
          messageText: connectionFactory.EDITABLE_TEXT,
          messageBox: connectionFactory.TEXT_BOX,
          noServicesDivider: connectionFactory.DIVIDER,
        }).build();

      return {
        controllersStageData: {
          [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
            default: mainAppConnection
          }
        },
        pages: {
          pageActions: {
            default: [
              'Pages_Actions_Page_Rename',
              {
                title: translation.t('bookings-pages.page.delete'),
                event: deletePageEvent,
                icon: 'deleteAction',
                type: 'page_remove',
              }
            ],
            bookCheckoutPage:  []
          },
          pageSettings: {
            default: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                },
                {
                  title: translation.t('bookings-pages.tabs.seo'),
                  type: 'seo'
                }
              ],
            bookCheckoutPage: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}`,
                  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                }
              ]
          },
          applicationSettings: {
            default: {
              displayName: translation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33'
            }
          },
          applicationActions: {
            default: {
              defaultValues: [{
                title: translation.t('bookings-pages.actions.manage'),
                event: manageBookingsEvent,
                icon: 'settingsAction'
              },
              {
                title: translation.t('bookings-pages.actions.delete'),
                event: deleteBookingsEvent,
                icon: 'deleteRadio'
              }]
            }
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType',
              orderIndex: 0
            },
            bookCheckoutPage: {
              icon: 'bookingPageType',
              orderIndex: -1
            }
          },
        }
      }
  },
    onEvent: async ({eventType, eventPayload}, editorSDK) => {
      function openManageBookings() {
        editorSDK.editor.openDashboardPanel(appToken, {url: bookingsDashboardPanel, closeOtherPanels: false});
      }

      switch (eventType) {
        case widgetGfppClicked:
          if (eventPayload.id === manageBookingsEvent) {
            openManageBookings()
          }
          break;
        case manageBookingsEvent:
          openManageBookings();
          break;
        case deleteBookingsEvent:
          const bookingsPages = await getAllBookingsPages(editorSDK, appToken);
          const essentialPage = bookingsPages.find(page => page.tpaPageId === bookingsListPageId);
          await removePage(editorSDK, appToken, essentialPage.id);
          break;
        case deletePageEvent:
          await removePage(editorSDK, appToken, eventPayload.pageRef.id);
          break;
        case pageChangedEvent:
          const pageData = await getPageData(sdk, appToken, eventPayload.pageRef);
          if (pageData.tpaPageId === 'scheduler' && isProposeMigrationNeeded && !(await isBookingsSplitted(editorSDK))) {
            await proposeMigration(sdk, appToken, locale, instance);
          }
          break;
      }
    },
    handleAction: async (args) => {
      const type = args.type, payload = args.payload;
      try {
        switch (type) {
          case 'migrate':
            if (!(await isBookingsSplitted(sdk))) {
              return handleMigration(sdk, appToken);
            } else {
              await handleLightMigration(sdk, appToken);
              return Promise.reject('Site is already migrated');
            }
          case 'appInstalled':
            switch (payload.appDefinitionId) {
              case MEMBERS_AREA_DEF_ID: {
                return addBookingsMembersArea(sdk, appToken);
              }
              default:
                return Promise.resolve();
            }
          case 'removeApp':
            return onRemoveApp(sdk, appToken);
          default:
            return Promise.resolve();
        }
      } catch (e) {
        Promise.reject(e);
        throw e;
      }
    },
    getControllerPresets: () => {
      return Promise.resolve([]);
    }
  };
};
