export interface FullActionDefinition {
  actionId: string;
  label: string;
}

export type ActionDefinition = FullActionDefinition | string;

export interface GfppDefinitions {
  mainAction1?: ActionDefinition;
  mainAction2?: ActionDefinition;
  iconButtons: {
    settings?: ActionDefinition;
    design?: ActionDefinition;
    crop?: ActionDefinition;
    filters?: ActionDefinition;
    animation?: ActionDefinition;
    link?: ActionDefinition;
    stretch?: ActionDefinition;
    layout?: ActionDefinition;
    upgrade?: ActionDefinition;
    connect?: ActionDefinition;
    pinMode?: ActionDefinition;
    textSize?: ActionDefinition;
    help?: ActionDefinition;
    customConnect?: FullActionDefinition;
  },
  helpId?: string,
}

export class ConnectionBuilder {
  private gfppDefs: GfppDefinitions = {
    iconButtons: {},
  };

  private connection = {
    behavior: {
      duplicatable: true,
      rotatable: true,
      pinnable: true,
      toggleShowOnAllPagesEnabled: true,
      canReparent: true,
      resizable: true,
      dataEditOptions: 'FULL',
    },
    connections: {},
  };

  withMainAction1(mainAction: ActionDefinition) {
    this.gfppDefs.mainAction1 = mainAction;
    return this;
  }

  withMainAction2(mainAction: ActionDefinition) {
    this.gfppDefs.mainAction2 = mainAction;
    return this;
  }

  withConnections(connections: any) {
    this.connection.connections = connections;
    return this;
  }

  withHelpId(helpId: string) {
    this.gfppDefs.helpId = helpId;
    return this;
  }

  withDisabledTextEditing() {
    this.connection.behavior.dataEditOptions = 'TEXT_STYLE_ONLY';
    return this;
  }

  withDisabledResizability() {
    this.connection.behavior.resizable = false;
    return this;
  }

  withDisabledDuplicability() {
    this.connection.behavior.duplicatable = false;
    return this;
  }

  withDisabledDataConnection() {
    this.gfppDefs.iconButtons.connect = 'HIDE';
    return this;
  }

  withDisabledAnimation() {
    this.gfppDefs.iconButtons.animation = 'HIDE';
    return this;
  }

  withDisabledLinkAbility() {
    this.gfppDefs.iconButtons.link = 'HIDE';
    return this;
  }

  withDisabledSettings() {
    this.gfppDefs.iconButtons.settings = 'HIDE';
    return this;
  }

  withDisabledDesign() {
    this.gfppDefs.iconButtons.design = 'HIDE';
    return this;
  }

  withoutHelpUrl() {
    this.gfppDefs.iconButtons.help = 'HIDE';
    this.gfppDefs.helpId = '';
    return this;
  }

  fullyDisabled() {
    this
      .withMainAction1('HIDE')
      .withMainAction2('HIDE')
      .withoutHelpUrl()
      .withDisabledTextEditing()
      .withDisabledResizability()
      .withDisabledDuplicability()
      .withDisabledAnimation()
      .withDisabledDesign()
      .withDisabledSettings();
    return this;
  }

  build() {
    return {
      ...this.connection,
      gfpp: {
        desktop: this.gfppDefs,
        mobile: this.gfppDefs,
      }
    }
  }
}

export class ConnectionBuilderFactory {
  constructor(private translation: any) {}

  public UNEDITABLE_TEXT = new ConnectionBuilder()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .withDisabledTextEditing()
    .build();

  public LINK = new ConnectionBuilder()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .withoutHelpUrl()
    .build();

  public LINK_DISABLED_TEXT = new ConnectionBuilder()
    .withMainAction1('HIDE')
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .withDisabledTextEditing()
    .withoutHelpUrl()
    .build();

  public REPEATER = new ConnectionBuilder()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .withoutHelpUrl()
    .build();

  public REPEATER_ITEM = new ConnectionBuilder()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .withoutHelpUrl()
    .build();

  public EDITABLE_TEXT = new ConnectionBuilder()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .build();

  public TEXT_BOX = new ConnectionBuilder()
    .withoutHelpUrl()
    .build();

  public DIVIDER = new ConnectionBuilder()
    .withoutHelpUrl()
    .build();

  public STATE_BOX = new ConnectionBuilder()
    .fullyDisabled()
    .build();

  public LOADER_IMAGE = new ConnectionBuilder()
    .withDisabledSettings()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .build();
}

