import {BOOKINGS_DEF_ID} from '../constants';
import {ComponentDataType, ComponentStructure, ComponentType} from '../migration/domain';

export const isBookingsSplitted = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(page => page.tpaPageId === "book_checkout");
};

export const onRemoveApp = async (sdk, appToken) => {
  try {
    const allComponentsPromises = (await sdk.document.components.getAllComponents(appToken))
      .map(componentRef => sdk.components.data.get('token', {componentRef}).then(componentData => ({
        componentData,
        componentRef
      })));
    const componentsToDelete = (await Promise.all(allComponentsPromises))
      .filter(({componentData}) => componentData && componentData.appDefinitionId === appToken && componentData.type === 'WidgetRef');
    await Promise.all(componentsToDelete.map(({componentRef}) => sdk.document.components.remove('token', {componentRef})));
    return 'OK';
  } catch (e) {
    console.error('Failed to remove Ref Widgets', e);
    return 'Error';
  }
};

export const getBookingsData = (sdk, appToken) => sdk.document.tpa.app.getDataByAppDefId(appToken, BOOKINGS_DEF_ID);

export const getEditorSdkSrc = (sdk) => sdk.info.getSdkVersion().scriptSrc;

export const updateComponentStyle = (sdk, appToken, compId, style) => sdk.document.components.style.update(appToken, {componentRef: {type: "DESKTOP", id: compId}, style});

export const updateComponentData = (sdk, appToken, componentRef, data) => sdk.document.components.data.update(appToken, {componentRef, data});

export const updatePageData = (sdk, appToken, pageRef, data) => sdk.pages.data.update(appToken, {pageRef, data});

export const getComponentData = (sdk, appToken, componentRef) => sdk.document.components.data.get(appToken, {componentRef});

export const getPageData = (sdk, appToken, pageRef) => sdk.document.pages.getPageData(appToken, {pageRef});

export const getComponentRefById = (sdk, appToken, compId) => sdk.document.components.getById(appToken, {id: compId});

export const getAllBookingsPages = (sdk, appToken) => sdk.document.pages.getApplicationPages(appToken);

export const navigateToPage = (sdk, appToken, pageId) => sdk.document.pages.navigateTo(appToken, {pageRef: {type: "DESKTOP", id: pageId}});

export const getAllSiteComponents = (sdk, appToken) => sdk.document.components.getAllComponents(appToken);

export const getFullComponentStructure = (sdk, appToken, componentRef) => sdk.document.components.serialize(appToken, {componentRef, maintainIdentifiers: true});

export const getPageRefByComponentRef = (sdk, appToken, componentRef) => sdk.components.getPage(appToken, {componentRef});

export const removePage = (sdk, appToken, pageId) => sdk.document.pages.remove(appToken, {pageRef : {id: pageId}});

export const getTPAMainSectionStructure = async (sdk, appToken, tpaId) => {
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return await Promise.all(allComponents.map(componentRef =>
      getFullComponentStructure(sdk, appToken, componentRef)
    ));
  })();
  const compStructures: ComponentStructure[] = siteStructure.filter(comp => comp.type === ComponentType.COMPONENT);

  return compStructures.find(comp => comp.data && comp.data.appDefinitionId === tpaId && comp.data.type === ComponentDataType.MAIN_SECTION);
};

export const getTPAMainSectionPageRef = async (sdk, appToken, tpaId) => {
  const tpaMainSectionStructure = await getTPAMainSectionStructure(sdk, appToken, tpaId);
  const tpaMainSectionRef = await getComponentRefById(sdk, appToken, tpaMainSectionStructure.id);
  return await getPageRefByComponentRef(sdk, appToken, tpaMainSectionRef);
};
